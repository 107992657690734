






























import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab3Procedure',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        procedureAnswer: null,
        language: 'en',
      },
      questions: [
        {
          en: 'In the space below, include your procedure for this experiment. You may reference the lab manual, but please note any changes you made to the procedure.',
          fr: "Dans l'espace ci-dessous, incluez votre procédure pour cette expérience. Vous pouvez vous référer au manuel de laboratoire, mais veuillez noter toutes les modifications que vous avez apportées à la procédure.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
